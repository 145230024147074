import { useQuery } from '@tanstack/react-query'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import buildUrl from '../utils/buildUrl'
import { Result } from 'services/types'
import { TacticalDetails } from './types'

export const fetchAgents = async () => {
  const response = await HttpClient.get<Result<TacticalDetails>>(
    buildUrl({
      route: endpoints.queryAgents,
      queryParams: { onlyUnfinishedDuties: true },
    }),
  )

  return {
    data: response.data.data,
    totalElements: response.data.totalElements,
  }
}

const getAgentDetails = async (
  occurrenceId = '',
  onlyUnfinishedDuties = false,
) => {
  const response = await HttpClient.get<Result<TacticalDetails>>(
    buildUrl({
      route: endpoints.queryAgents,
      queryParams: { occurrenceId, onlyUnfinishedDuties },
    }),
  )

  return response.data.data
}

export const useGetAgentDetails = (
  occurrenceId = '',
  onlyUnfinishedDuties = false,
) =>
  useQuery({
    queryKey: ['get-agent', occurrenceId],
    queryFn: async () =>
      await getAgentDetails(occurrenceId, onlyUnfinishedDuties),
  })
