import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'
import { FetchNextPageOptions, useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { ServiceType } from 'services/serviceType/types'
import { ServiceTypeDriver } from 'services/serviceType'

type UseGetServiceType = {
  serviceTypes: ServiceType[] | undefined
  isFetching: boolean
  isError: boolean
  fetchNextServiceTypesPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<unknown>
}

export const getServiceTypes = async (filter?: string, offset = 0) => {
  const response = await ServiceTypeDriver.query({
    offset,
    ...(filter && { name: filter }),
  })

  return response
}

export const getServiceType = async (filter?: string, offset = 0) => {
  const response = await ServiceTypeDriver.query({
    offset,
    recordsPerPage: 15,
    ...(filter && { name: filter }),
  })

  return {
    data: response.data,
    totalElements: response.totalElements,
  }
}

export function useGetServiceType(filter?: string): UseGetServiceType {
  const {
    isError,
    data,
    isFetching,
    fetchNextPage: fetchNextServiceTypesPage,
  } = useInfiniteQuery({
    queryKey: ['serviceTypes', filter],
    initialPageParam: 0,
    queryFn: ({ pageParam }) => getServiceTypes(filter, pageParam),
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<ServiceType>(lastPage, allPages),
  })

  const serviceTypes = useMemo(() => flattenPages<ServiceType>(data), [data])

  return {
    isError,
    serviceTypes,
    fetchNextServiceTypesPage,
    isFetching,
  }
}
