import { useCallback, useState } from 'react'

import { Button, Icon, Popover } from 'components'
import { ReactComponent as CentralAccount } from 'assets/svg/centralAccount.svg'
import { ReactComponent as CentralGroup } from 'assets/svg/centralGroup.svg'
import { ReactComponent as QRCodeIcon } from 'assets/svg/qrCode.svg'
import { ReactComponent as WiredConnetion } from 'assets/svg/wiredConnetion.svg'
import { ReactComponent as WiredConnetionOff } from 'assets/svg/wiredDisconnected.svg'
import { ReactComponent as GPRSConnection } from 'assets/svg/gprsConnection.svg'
import { ReactComponent as GPRSConnectionOff } from 'assets/svg/gprsDisconnected.svg'
import { ReactComponent as PlusSign } from 'assets/svg/plusSign.svg'
import { ReactComponent as Wifi } from 'assets/svg/smallWifi.svg'
import { ReactComponent as WifiOff } from 'assets/svg/wifiDisconnected.svg'
import { ReactComponent as Gear } from 'assets/svg/gear.svg'

import styles from './CentralCardFooter.module.scss'

import { centralCodeMasker } from 'domains/customer/screens/Equipments/utilities/masks/masks'
import { CentralVersions } from 'domains/customer/screens/Equipments/components/CentralVersions/CentralVersions'
import { useToast, useToggle } from 'shared/hooks'

import {
  getBatteryIcon,
  getConnectionIpWithMode,
} from 'domains/customer/screens/Equipments/utilities/central'
import { PowerStatus } from 'services/serviceOrder'
import { getSignalLevelBarIcon } from 'domains/customer/screens/Equipments/utilities/icons'
import {
  CentralAggregatedResponse,
  CentralContactList,
  PartitionFragmentPayload,
} from 'services/central/types'
import { usePatchCentral } from 'services/central'
import { DragAndDrop } from 'domains/attendancePolicy/components/AutomationList/components/DragAndDropAutomation/DragAndDropAutomation'
import { formatPhone } from 'utilities/masks'
import { PhoneType } from 'services/contact/types'
import { AddContactModal } from 'domains/customer/components/CentralForm/components'
import { usePostAndHandleInfoRequestCommand } from '../../hooks/services/commands'
import { ContactResponse } from 'domains/customer/components/CentralForm/types'
import ConnectionRichTooltip from '../ConnectionRichTooltip'
import Loader from '../Loader'

export interface CentralCardFooterProps {
  central: CentralAggregatedResponse
  lastFetchDate: string
  refetch: () => void
}

export const CentralCardFooter = ({
  central,
  lastFetchDate,
  refetch,
}: CentralCardFooterProps) => {
  const centralVersionsModal = useToggle()
  const addContactModal = useToggle()

  const [selectedContact, setSelectedContact] = useState<ContactResponse>()

  const getContactItem = (
    contact: PartitionFragmentPayload,
  ): {
    id: string
    code: string
    title: string
    subtitle: string
    email: string
    phone: string
  } => ({
    id: contact.id,
    code: contact.code,
    title: contact.name,
    subtitle: `Posição ${contact.code}`,
    email: contact.email || '',
    phone:
      contact.phone &&
      formatPhone(
        PhoneType.CellPhone,
        `${contact.phone.provinceCode}${contact.phone.number}`,
      ),
  })

  const { mutatePatchCentral } = usePatchCentral(central.id)

  const { addToast } = useToast()

  const { mutateInfoRequest, infoRequestStatus } =
    usePostAndHandleInfoRequestCommand(central.id, refetch)

  const handleMutatePatchCentral = useCallback(
    (value: CentralContactList[], onRemove = false) => {
      const centralPartitions =
        central.partitions?.map((partition) => ({
          code: partition.code,
          id: partition.id,
          name: partition.name,
          status: partition.status,
          keyNumber: '',
        })) || []

      mutatePatchCentral(
        {
          contacts: value.map((contact) => ({
            code: contact.code,
            contactId: contact.contactId,
            partitionIds: contact.partitionIds,
          })),
          central: {
            code: central.code,
            ...(central.model && {
              model: {
                id: central.model?.id,
                name: central.model?.name,
                hasCommandSupport: central.hasCommandSupport,
              },
            }),
            externalId: central.externalId,
            topicGroup: central.topicGroup,
          },
          partitions: centralPartitions,
        },
        {
          onSuccess: () => {
            addToast({
              message: onRemove
                ? 'Contato removido com sucesso.'
                : `Contato ${
                    selectedContact ? 'editado' : 'adicionado'
                  } com sucesso.`,
              type: 'success',
            })
            addContactModal.hide()
            refetch()
          },
          onError: () =>
            addToast({
              message: onRemove
                ? 'Erro ao remover contato. Tente novamente'
                : `Erro ao  ${
                    selectedContact ? 'editar' : 'adicionar'
                  } contato. Tente novamente`,
              type: 'alert',
              error: true,
            }),
        },
      )
    },
    [
      central,
      mutatePatchCentral,
      refetch,
      selectedContact,
      addToast,
      addContactModal,
    ],
  )

  return (
    <>
      {infoRequestStatus === 'pending' && <Loader />}
      {addContactModal.isVisible && (
        <AddContactModal
          onSave={(newContact) => {
            handleMutatePatchCentral(
              [
                ...(central?.centralUsers?.filter(
                  (contact) => contact.code !== selectedContact?.code,
                ) || []),
                newContact,
              ].map((contact) => ({
                contactId: contact.id,
                code: contact.code,
                partitionIds: contact.partitionIds,
              })),
            )
          }}
          onClose={addContactModal.hide}
          contacts={central.centralUsers}
          selectedContact={selectedContact}
        />
      )}

      <CentralVersions
        softwareVersion={{
          ...central.versions,
          modelName: central.model?.name || '',
        }}
        onClose={centralVersionsModal.hide}
        isVisible={centralVersionsModal.isVisible}
      />

      <div className={styles.centralDetails}>
        <div className={styles.item}>
          <div className={styles.icon}>
            <QRCodeIcon aria-label="qrcode-icon" />
          </div>
          <div className={styles.infos}>
            <div className={styles.wrapperLabel}>
              <label className={styles.label}>Código</label>
            </div>
            <span>{central?.code ? centralCodeMasker(central.code) : '-'}</span>
          </div>
        </div>

        {!central.hasCommandSupport && (
          <>
            <div className={styles.item}>
              <div className={styles.icon}>
                <CentralGroup />
              </div>
              <div className={styles.infos}>
                <div className={styles.wrapperLabel}>
                  <label className={styles.label}>Grupo de centrais</label>
                </div>
                <span>{central.topicGroup || '-'}</span>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.icon}>
                <CentralAccount />
              </div>
              <div className={styles.infos}>
                <div className={styles.wrapperLabel}>
                  <label className={styles.label}>Conta da central</label>
                </div>
                <span>{central.externalId || '-'}</span>
              </div>
            </div>
          </>
        )}

        <div className={styles.item}>
          <div className={styles.icon}>
            {central.ethernet?.connectionStatus ? (
              <WiredConnetion aria-label="ethernet-on" />
            ) : (
              <WiredConnetionOff aria-label="ethernet-off" />
            )}
          </div>
          <div className={styles.infos} aria-label="ethernet-info">
            <div className={styles.wrapperLabel}>
              <label className={styles.label}>Rede com fio</label>
            </div>
            {central.ethernet ? (
              <>
                {central.ethernet?.connectionStatus ? (
                  <div
                    className={styles.connectionDetails}
                    aria-label="connection-details"
                  >
                    <span>Conectada</span>
                    <span>
                      {getConnectionIpWithMode(
                        central.ethernet?.ipMode,
                        central.ethernet?.ip,
                      )}
                    </span>
                  </div>
                ) : (
                  <span>Desconectada</span>
                )}
              </>
            ) : (
              '-'
            )}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.icon}>
            {central.wifi?.connectionStatus ? (
              <Wifi aria-label="wifi-on" />
            ) : (
              <WifiOff aria-label="wifi-off" />
            )}
          </div>
          <div className={styles.infos} aria-label="wifi-info">
            <div className={styles.wrapperLabel}>
              <label className={styles.label}>Wi-Fi</label>
              {central.wifi && (
                <div className={styles.signalIcon}>
                  {getSignalLevelBarIcon(central.wifi.signal)}
                </div>
              )}
            </div>
            {central.wifi ? (
              <>
                {central.wifi?.connectionStatus ? (
                  <div
                    className={styles.connectionDetails}
                    aria-label="connection-details"
                  >
                    <span>{central.wifi.ssid}</span>
                    <span>
                      {getConnectionIpWithMode(
                        central.wifi.ipMode,
                        central.wifi.ip,
                      )}
                    </span>
                  </div>
                ) : (
                  <span>Desconectada</span>
                )}
              </>
            ) : (
              '-'
            )}
          </div>
        </div>

        <Popover.Root>
          <Popover.Trigger asChild>
            <div className={styles.item}>
              <div className={styles.icon}>
                {central.gprs?.connectionStatus ? (
                  <GPRSConnection aria-label="gprs-on" />
                ) : (
                  <GPRSConnectionOff aria-label="gprs-off" />
                )}
              </div>
              <div className={styles.infos} aria-label="gprs-info">
                <div className={styles.wrapperLabel}>
                  <label className={styles.label}>3G/4G</label>
                  {central.gprs && (
                    <div className={styles.signalIcon}>
                      {getSignalLevelBarIcon(central.gprs.signal)}
                    </div>
                  )}
                </div>
                {central.gprs ? (
                  <>
                    {central.gprs?.connectionStatus ? (
                      <div
                        aria-label="connection-details"
                        className={styles.connection}
                      >
                        <span>{central.gprs.telecom.toUpperCase()}</span>
                      </div>
                    ) : (
                      <span>Desconectada</span>
                    )}
                  </>
                ) : (
                  '-'
                )}
              </div>
            </div>
          </Popover.Trigger>
          <Popover.Content position="top">
            <ConnectionRichTooltip
              ICCID={central.gprs?.iccid || ''}
              phone={central.gprs?.moduleNumber || ''}
            />
          </Popover.Content>
        </Popover.Root>

        <div className={styles.item}>
          <div className={styles.icon}>
            {getBatteryIcon(
              central.batteryLevel ? central.batteryLevel : 100,
              central.powerStatus === PowerStatus.ON,
            )}
          </div>
          <div className={styles.infos}>
            <div className={styles.wrapperLabel}>
              <label className={styles.label}>
                {central.powerStatus === PowerStatus.ON
                  ? 'Conectado à energia'
                  : 'Energia'}
              </label>
            </div>
            <span>
              {central.batteryLevel ? `${central.batteryLevel}% de carga` : '-'}
            </span>
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.icon}>
            <Gear aria-label="gear-icon" />
          </div>
          <div className={styles.infos}>
            <div className={styles.wrapperLabel}>
              <label className={styles.label}>Versões</label>
            </div>
            <div>
              <Button
                className={styles.versionButton}
                buttonTitle="Visualizar"
                type="tertiary"
                onClick={() => centralVersionsModal.show()}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.partitionsContainer}>
        <div className={styles.partitionsHeader}>
          <h2 className={styles.partitionsHeaderTitle}>Usuários da central</h2>
          <Button
            buttonTitle="Novo usuário"
            type="tertiary"
            icon={PlusSign}
            onClick={() => {
              setSelectedContact(undefined)
              addContactModal.show()
            }}
          />
        </div>
        {!!central.centralUsers?.length && (
          <DragAndDrop
            draggable={false}
            getItem={getContactItem}
            items={central.centralUsers
              ?.map((contact) => ({
                id: contact.code,
                code: contact.code,
                email: contact.email,
                name: contact.name || '',
                phone: {
                  provinceCode: contact.phones?.[0].provinceCode,
                  number: contact.phones?.[0].number,
                },
              }))
              .sort((a, b) => Number(a.code) - Number(b.code))}
            onEdit={(contactToEdit) => {
              setSelectedContact(
                central?.centralUsers?.find(
                  (contact) => contact.name === contactToEdit.title,
                ),
              )
              addContactModal.show()
            }}
            onDelete={(contactToDelete) => {
              if (central.centralUsers) {
                handleMutatePatchCentral(
                  [
                    ...central.centralUsers?.filter(
                      (contact) => contact.code !== contactToDelete.code,
                    ),
                  ].map((contact) => ({
                    contactId: contact.id,
                    code: contact.code,
                  })),
                  true,
                )
              }
            }}
          />
        )}
      </div>
      <div className={styles.updateCentralContainer}>
        <p className={styles.dateInfo}>
          {`Última atualização em: ${lastFetchDate || ''}.`}
        </p>
        {central.hasCommandSupport && (
          <div className={styles.updateButtonWrapper}>
            <Icon name="refresh" width={12} />
            <button
              onClick={() => mutateInfoRequest()}
              className={styles.updateCentralButton}
            >
              Atualizar
            </button>
          </div>
        )}
      </div>
    </>
  )
}
