import { ContactResponse } from 'domains/customer/components/CentralForm/types'
import { AggregatedAccount } from 'services/account/types'
import { ActionPlan } from 'services/attendance/types'
import { PhoneResponse } from 'services/contact/types'
import { PartitionStatus } from 'services/patrimony/types'
import { ConnectionIPMode } from 'services/serviceOrder'

export enum CENTRAL_MODE {
  'DEFAULT' = 1,
  'MAINTENANCE' = 2,
  'STANDBY' = 3,
}

export type RemoteControls = {
  id: string
  name: string
}

export type PartitionContacts = {
  id: string
  name: string
  type: string
  remoteControls?: RemoteControls[]
  email?: string
  phone?: PhoneResponse
}

export type PartitionFragment = {
  id: string
  code: string
  name: string
  deletionWarning?: {
    linkedDevices?: boolean
    defaultPartition?: boolean
  }
  status: PartitionStatus
  actionPlan?: ActionPlan[]
  contacts?: PartitionContacts[]
  externalId?: string
}

export type CentralAggregatedResponse = {
  id: string
  connected: boolean
  lastConnectionTimestamp: number
  code: string
  powerStatus?: number
  batteryLevel?: number
  tamper: boolean
  demo: boolean
  mode?: CENTRAL_MODE
  installationImageId?: string
  hasCommandSupport: boolean
  externalId?: string
  topicGroup?: string
  aggregatedAccountName?: string
  serviceType?: {
    id: string
    name: string
  }
  manufacturer?: {
    id: string
    name: string
  }
  model?: {
    id: string
    name: string
  }
  safeCall?: {
    enabled: boolean
    delay: number
    number: number
  }
  versions: {
    firmware?: string
    software?: string
    centralCore?: string
  }
  ethernet?: {
    connectionMode: number
    connectionStatus: boolean
    ipMode: ConnectionIPMode
    ip: string
  }
  wifi?: {
    connectionStatus: boolean
    signal: number
    ipMode: ConnectionIPMode
    ip: string
    ssid: string
  }
  gprs?: {
    connectionStatus: boolean
    telecom: string
    iccid: string
    signal: number
    moduleNumber?: string
  }
  partitions?: PartitionFragment[]
  createdAt: number
  centralUsers?: ContactResponse[]
}

export type CentralPartition = {
  id: string
  name: string
  code: string
  status: PartitionStatus
}

export type PartitionFragmentPayload = {
  id: string
  name: string
  code: string
  keyNumber?: string
  status?: PartitionStatus
  email?: string
  phone?: {
    provinceCode: string
    number: number
  }
  externalId?: string
}

export type CentralContactList = {
  id?: string
  contactId?: string
  code: string
  name?: string
  partitionIds?: string[]
}

export type UpdateCentralPayload = {
  central: {
    code: string
    model?: {
      id: string
      name: string
      hasCommandSupport: boolean
    }
    externalId?: string
    topicGroup?: string
  }
  contacts: CentralContactList[]
  partitions: PartitionFragmentPayload[]
  image?: File
}

export type Livecycle = 'ALPHA' | 'BETA' | 'RELEASE'

export enum CentralStatus {
  Available = 'AVAILABLE',
  Installed = 'INSTALLED',
  Uninstalled = 'UNINSTALLED',
}

export type CentralResponse = {
  id: string
  code?: string
  name: string
  model: {
    id: string
    name: string
    hasCommandSupport: boolean
  }
  manufacturer: {
    id: string
    name: string
  }
  account: {
    id: string
    name: string
    aggregatedAccountName: string
  }
  externalId?: string
  versionCode: number
  topicGroup?: string
  connected: boolean
  livecycle: Livecycle
  mode: number
  status: CentralStatus
  lastConnectStatus: number
  accountId?: string
  publicKey?: string
  privateKey?: string
  certificatePem?: string
  createdAt: number
  updatedAt: number
  partitions: PartitionFragmentPayload[]
  installationImageId?: string
}

export type CreateCentralPayload = {
  account: Pick<AggregatedAccount, 'aggregatedAccountName' | 'id'>
  central: CentralResponse
  contacts: CentralContactList[]
  partitions: PartitionFragmentPayload[]
  image?: File
}

export type CentralFindByIdResult = {
  central: CentralResponse & {
    installationImageId: string
  }
  contacts: CentralContactList[]
  partitions: PartitionFragment[]
}
