import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Customer from '../GeneralInfo/GeneralInfo'
import Patrimony from '../Patrimonies/Patrimonies'
import Contacts from '../Contacts/Contacts'
import { Equipments } from '../Equipments/Equipments'
import History from '../History/History'
import { OfficeHoursList } from 'domains/customer/screens/OfficeHours/screens'
import { Patrol } from 'domains/Patrol'

import { Breadcrumbs, TabBar, Toast } from 'components'
import { Tabs } from 'components/Tabs'

import { CustomerPaths } from 'routes/constants/paths/customer'
import {
  SERVICE_ORDER_STATUS_ENUM,
  ServiceOrderAggregatedQueryResponse,
} from 'services/serviceOrder'
import { useToast, useToggle } from 'shared/hooks'
import { getServiceOrderToastMessage } from 'domains/customer/utilities/serviceOrder/getServiceOrderMessage'
import { useGetServiceOrders } from 'shared/hooks/serviceOrder/useGetServiceOrders'
import { TabData } from 'components/Tabs/types'

import styles from './CustomerManagementTabs.module.scss'
import { Central } from '../Central/Central'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import { ReactComponent as ShopIcon } from 'assets/svg/shop.svg'
import { ReactComponent as Icon } from 'assets/svg/exclamationMessageBlue.svg'

export enum CUSTOMER_TABS {
  'ACCOUNT' = 'account',
  'PATRIMONIES' = 'patrimonies',
  'CENTRAL' = 'central',
  'CONTACTS' = 'contacts',
  'EQUIPMENTS' = 'equipments',
  'HISTORY' = 'history',
  'OFFICE_HOURS' = 'officeHours',
}

const tabData: TabData[] = [
  {
    id: '0',
    label: 'Horários de funcionamento',
    icon: <ShopIcon />,
    content: <OfficeHoursList />,
    path: CustomerPaths.OfficeHours,
  },
  {
    id: '1',
    label: 'Horários de ocorrências periódicas',
    icon: <Icon />,
    content: <Patrol />,
    path: CustomerPaths.Patrol,
  },
]

export const CustomerManagement = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { clearAllLocalStorageFilters } = usePersistentFilters()

  const [path] = location.pathname.split('/').reverse()

  const [activeTabPath, setActiveTabPath] = useState<string>(path)

  const [serviceOrder, setServiceOrder] =
    useState<ServiceOrderAggregatedQueryResponse>()

  const [toastInfo, setToastInfo] = useState({
    message: '',
    buttonTitle: '',
  })

  const customerName = localStorage.getItem('customerName')
  const accountId = localStorage.getItem('accountId')

  const informativeToast = useToggle()

  const toast = useToast()

  const { data: serviceOrders, status } = useGetServiceOrders(
    {
      accountIds: [accountId || ''],
      status: [
        SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS,
        SERVICE_ORDER_STATUS_ENUM.OPEN,
        SERVICE_ORDER_STATUS_ENUM.SCHEDULED,
        SERVICE_ORDER_STATUS_ENUM.RESCHEDULED,
        SERVICE_ORDER_STATUS_ENUM.PAUSED,
        SERVICE_ORDER_STATUS_ENUM.REPROVED,
      ],
    },
    Boolean(accountId),
  )

  useEffect(() => {
    if (serviceOrders?.totalElements) {
      const [serviceOrder] = serviceOrders.data

      setServiceOrder(serviceOrder)

      setToastInfo(
        getServiceOrderToastMessage(serviceOrder.status, serviceOrder.number),
      )

      informativeToast.show()
    }

    if (status === 'error') {
      toast.addToast({
        type: 'alert',
        message:
          'Não foi possível recuperar as ordens de serviço. Tente novamente',
      })
    }
  }, [serviceOrders, status, toast])

  useEffect(() => {
    setActiveTabPath(path)
  }, [path])

  return (
    <>
      <div className={styles.wrapperBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Home', href: '/' },
            { title: 'Cliente', href: '/account/list' },
            {
              title: customerName || '',
              href: '',
            },
          ]}
        />
      </div>

      <Toast
        isVisible={informativeToast.isVisible}
        onClose={informativeToast.hide}
        type="informative"
        autohideTimeout={0}
      >
        {toastInfo.message}
        <button
          aria-label="navigate-to-service-order"
          className={styles.toastButton}
          onClick={() => {
            if (serviceOrder) {
              informativeToast.hide()
              navigate(`/so/info/${serviceOrder.id || ''}`)
            }
          }}
        >
          {toastInfo.buttonTitle}
        </button>
      </Toast>

      <TabBar>
        <TabBar.Tab
          id={CUSTOMER_TABS.ACCOUNT}
          idSelected={activeTabPath}
          label="Cliente"
          onClick={() => {
            navigate(CustomerPaths.Account, { replace: true })
            clearAllLocalStorageFilters()
          }}
        >
          <Customer />
        </TabBar.Tab>
        <TabBar.Tab
          id={CUSTOMER_TABS.HISTORY}
          idSelected={activeTabPath}
          label="Histórico"
          onClick={() => {
            navigate(CustomerPaths.History, { replace: true })
            clearAllLocalStorageFilters()
          }}
        >
          <History />
        </TabBar.Tab>
        <TabBar.Tab
          id={CUSTOMER_TABS.PATRIMONIES}
          idSelected={activeTabPath}
          label="Patrimônios"
          onClick={() => {
            navigate(CustomerPaths.Patrimonies, { replace: true })
            clearAllLocalStorageFilters()
          }}
        >
          <Patrimony />
        </TabBar.Tab>
        <TabBar.Tab
          id={CUSTOMER_TABS.CENTRAL}
          idSelected={activeTabPath}
          label="Centrais"
          onClick={() => {
            navigate(CustomerPaths.Central, { replace: true })
            clearAllLocalStorageFilters()
          }}
        >
          <Central />
        </TabBar.Tab>
        <TabBar.Tab
          id={CUSTOMER_TABS.CONTACTS}
          idSelected={activeTabPath}
          label="Contatos"
          onClick={() => {
            navigate(CustomerPaths.Contacts, { replace: true })
            clearAllLocalStorageFilters()
          }}
        >
          <Contacts />
        </TabBar.Tab>
        <TabBar.Tab
          id={CUSTOMER_TABS.EQUIPMENTS}
          idSelected={activeTabPath}
          label="Equipamentos"
          onClick={() => {
            navigate(CustomerPaths.Equipments, { replace: true })
          }}
        >
          <Equipments />
        </TabBar.Tab>
        <TabBar.Tab
          id={CUSTOMER_TABS.OFFICE_HOURS}
          idSelected={activeTabPath}
          label="Horários"
          onClick={() => {
            navigate(CustomerPaths.OfficeHours, { replace: true })
            clearAllLocalStorageFilters()
          }}
        >
          <Tabs tabs={tabData} />
        </TabBar.Tab>
      </TabBar>
    </>
  )
}

export default CustomerManagement
