import { UserFormData } from 'domains/user/types'
import { useMutation } from '@tanstack/react-query'
import { AuthDriver, UserResponse } from 'services/auth'

function parsePhoneNumber(phone?: string) {
  if (phone?.length) {
    const [, provinceCode, numberPart] = phone?.match(
      /\((\d{2})\) (\d{4,5}-\d{4})/,
    )

    const cleanedNumberPart = numberPart?.replace(/-/g, '')

    return {
      countryCode: 55,
      provinceCode: parseInt(provinceCode),
      phoneNumber: parseInt(cleanedNumberPart),
    }
  }

  return {
    countryCode: 55,
    provinceCode: 0,
    phoneNumber: 0,
  }
}

function createUser(data: UserFormData): Promise<UserResponse> {
  return AuthDriver.createUser({
    name: data.name,
    username: data.username,
    email: data.currentEmail,
    document: data.document?.replace(/[.\-/]/g, ''),
    confirmPassword: data.confirmPassword,
    password: data.confirmPassword,
    phone: {
      ...parsePhoneNumber(data?.phone),
    },
    address: {
      address: data.address || '',
      number: data.number || '',
      adjunct: data.adjunct || '',
      districtId: data.district?.id || '',
      postalCode: Number(data.postalCode?.replace(/\D/g, '')) || 0,
      reference: data.reference || '',
    },
    permissionGroups: data.permissionGroups?.map(
      (permissionGroup) => permissionGroup.id,
    ),
    phoneExtension: data.phoneExtension,
    tags: data.tags?.map((tag) => ({ name: tag })),
    userCoverages: {
      primary:
        data.userCoverages?.primary?.map(
          (primaryCoverage) => primaryCoverage.id,
        ) || [],
      secondary:
        data.userCoverages?.secondary?.map(
          (secondaryCoverage) => secondaryCoverage.id,
        ) || [],
    },
  })
}

export const usePostUser = () => {
  const { status, mutate } = useMutation({
    mutationFn: async (data: UserFormData) => {
      const result = await createUser(data)
      return result
    },
  })

  return {
    mutateCreateUser: mutate,
    statusCreateUser: status,
  }
}
