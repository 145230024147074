import { useCallback, useState } from 'react'

import { getPartitionStatusLabel } from 'domains/customer/utilities/partitions/getPartitionStatusLabel'
import { usePostPartitionContacts } from 'services/partition/hooks/usePostPartitionContacts'
import { usePutPartitionContacts } from 'services/partition/hooks/usePutPartitionContacts'
import { getContactTypeLabel } from 'domains/customer/utilities/contact'
import { AddContactModal } from '../AddContactModal'
import { DragAndDrop } from '../DragAndDropContacts'
import { useToast, useToggle } from 'shared/hooks'
import { Button, ButtonV2, ModalV2 } from 'components'

import styles from './PartitionsCardFooter.module.scss'
import { ActionPlan } from 'services/attendance/types'
import { ContactType } from 'services/partition/types'
import { PhoneType } from 'services/contact/types'
import { ReactComponent as ControlIcon } from 'assets/svg/control.svg'
import { ReactComponent as PlusSign } from 'assets/svg/plusSign.svg'
import { formatPhone } from 'utilities/masks'
import {
  CentralAggregatedResponse,
  PartitionContacts,
  PartitionFragment,
  RemoteControls,
} from 'services/central/types'
import Loader from '../Loader'
import { useRemovePartition } from 'services/partition'
import DeleteButton from './components/ButtonWithTooltip'

export interface CentralCardFooterProps {
  central: CentralAggregatedResponse
  lastFetchDate: string
  accountIds: string[]
  refetch: () => void
}

type PartitionContactsPayload = {
  actionable: boolean
  contactId: string
  priority: number
  remoteControls?: RemoteControls[]
}

const othersContacts = (
  contacts?: PartitionContacts[],
  lastPriorityOrder?: number,
): PartitionContactsPayload[] | undefined =>
  contacts?.map((contact) => ({
    actionable: false,
    contactId: contact.id,
    remoteControls: contact.remoteControls,
    priority: lastPriorityOrder
      ? lastPriorityOrder + 1
      : contact.phone?.priorityOrder || 1,
  }))

const actionPlanContacts = (
  actionPlan?: ActionPlan[],
): PartitionContactsPayload[] | undefined =>
  actionPlan?.map((contact, index) => ({
    actionable: true,
    contactId: contact.id,
    remoteControls: contact.remoteControls,
    priority: actionPlan.length > 1 ? index + 1 : contact.priorityOrder,
  }))

export const PartitionsCardFooter: React.FC<CentralCardFooterProps> = ({
  central,
  refetch,
  accountIds,
}: CentralCardFooterProps) => {
  const { mutate: mutatePostPartitionContacts, isPending } =
    usePostPartitionContacts()
  const { mutate: mutatePutPartitionContacts } = usePutPartitionContacts()
  const accountId = [localStorage.getItem('accountId') || '']
  const [currentPartition, setCurrentPartition] = useState<PartitionFragment>()
  const addContactModal = useToggle()
  const removePartitionModal = useToggle()
  const { addToast } = useToast()

  const handleRemoveContact = useCallback(
    (
      partitionId: string,
      contactId: string,
      type: 'actionPlan' | 'contact',
    ) => {
      const currentPartition = central.partitions?.find(
        (partition) => partition.id === partitionId,
      )

      const actionPlan = actionPlanContacts(currentPartition?.actionPlan) || []
      const contacts =
        othersContacts(
          currentPartition?.contacts,
          actionPlan[actionPlan.length - 1]?.priority,
        ) || []

      let partitionContacts: Omit<
        PartitionContactsPayload[],
        'remoteControls'
      > = [...actionPlan, ...contacts].map((contact) => ({
        actionable:
          contact.contactId !== contactId ? contact.actionable : false,
        contactId: contact.contactId,
        priority: contact.priority || 1,
      }))

      if (type === 'contact') {
        partitionContacts = partitionContacts.filter(
          (contact) => contact.contactId !== contactId,
        )
      }

      mutatePostPartitionContacts(
        { partitionId, partitionContacts },
        {
          onSuccess: () => {
            addToast({ message: 'Contato removido com sucesso.' })

            refetch()
          },
          onError: () => {
            addToast({
              message: 'Erro ao remover contato. Tente novamente.',
              type: 'alert',
            })
          },
        },
      )
    },
    [central.partitions, mutatePostPartitionContacts, addToast, refetch],
  )

  const { mutate: removePartition, isPending: removePartitionIsPending } =
    useRemovePartition(accountId)

  const onRemovePartition = (partitionId: string) => {
    removePartition(partitionId, {
      onSuccess: () => {
        addToast({
          message: 'Partição removida com sucesso.',
        })
        removePartitionModal.hide()
      },
      onError: () => {
        addToast({
          message: 'Erro ao remover partição. Tente novamente.',
          type: 'alert',
        })
      },
    })
  }

  const handleAddActionPlan = useCallback(
    (partitionId: string, contactId: string) => {
      const currentPartition = central.partitions?.find(
        (partition) => partition.id === partitionId,
      )

      const actionPlan =
        actionPlanContacts(
          currentPartition?.actionPlan?.sort(
            (a, b) => a.priorityOrder - b.priorityOrder,
          ),
        ) || []
      const contacts =
        othersContacts(
          currentPartition?.contacts,
          actionPlan[actionPlan.length - 1]?.priority,
        ) || []

      const partitionContacts: Omit<
        PartitionContactsPayload[],
        'remoteControls'
      > = [...actionPlan, ...contacts].map((contact) => ({
        actionable: contact.contactId !== contactId ? contact.actionable : true,
        contactId: contact.contactId,
        priority: contact.priority || 1,
      }))

      mutatePostPartitionContacts(
        { partitionId, partitionContacts },
        {
          onSuccess: () => {
            addToast({
              message: 'Contato adicionado ao plano de ação com sucesso.',
            })

            refetch()
          },
          onError: () => {
            addToast({
              message:
                'Erro ao adicionar contato ao plano de ação. Tente novamente.',
              type: 'alert',
            })
          },
        },
      )
    },
    [addToast, central.partitions, mutatePostPartitionContacts, refetch],
  )

  const handleChangeRemoteControl = useCallback(
    (
      item: PartitionContacts,
      partitionId: string,
      oldContact: PartitionContacts,
    ) => {
      const currentPartition = central.partitions?.find(
        (partition) => partition.id === partitionId,
      )

      const actionPlan = actionPlanContacts(currentPartition?.actionPlan) || []
      const contacts =
        othersContacts(
          currentPartition?.contacts,
          actionPlan[actionPlan.length - 1]?.priority,
        ) || []

      const currentRemoteControl = oldContact.remoteControls

      const partitionContacts = [...actionPlan, ...contacts].map((contact) => ({
        id: contact.contactId,
        priorityOrder: contact.priority,
        actionable: contact.actionable,
        remoteControls:
          contact.contactId === oldContact.id
            ? []
            : contact.contactId === item.id
              ? currentRemoteControl
              : contact.remoteControls,
      }))

      if (currentPartition) {
        mutatePutPartitionContacts(
          {
            partitionId,
            partition: {
              centralId: central.id,
              code: currentPartition.code,
              name: currentPartition.name,
              contacts: partitionContacts,
            },
          },
          {
            onSuccess: () => {
              addToast({
                message: 'Controle vinculado com sucesso.',
              })

              refetch()
            },
            onError: () => {
              addToast({
                message: 'Erro ao vincular controle. Tente novamente.',
                type: 'alert',
                error: true,
              })
            },
          },
        )
      }
    },
    [
      addToast,
      central.id,
      central.partitions,
      mutatePutPartitionContacts,
      refetch,
    ],
  )

  const handleOrdinationActionPlan = useCallback(
    (value: ActionPlan[], partitionId: string) => {
      const actionPlan = value.map((contact, index) => ({
        actionable: true,
        contactId: contact.id,
        remoteControls: contact.remoteControls,
        priority: index + 1,
      }))
      const contacts =
        othersContacts(
          central.partitions?.find((partition) => partition.id === partitionId)
            ?.contacts,
          actionPlan[actionPlan.length - 1]?.priority,
        ) || []

      const partitionContacts = [...actionPlan, ...contacts].map((contact) => ({
        actionable: contact.actionable,
        contactId: contact.contactId,
        priority: contact.priority,
      }))

      mutatePostPartitionContacts(
        { partitionId, partitionContacts, accountIds, centralId: central.id },
        {
          onSuccess: () => {
            addToast({ message: 'Plano de ação ordenado com sucesso.' })
          },
          onError: () => {
            addToast({
              message: 'Erro ao ordenar plano de ação. Tente novamente.',
              type: 'alert',
            })
          },
        },
      )
    },
    [
      accountIds,
      addToast,
      central.id,
      central.partitions,
      mutatePostPartitionContacts,
    ],
  )

  const handleAddPartitionContact = useCallback(
    (contactId: string, partitionId: string) => {
      const currentPartition = central.partitions?.find(
        (partition) => partition.id === partitionId,
      )

      const actionPlan = actionPlanContacts(currentPartition?.actionPlan) || []
      const contacts =
        othersContacts([
          ...currentPartition?.contacts,
          { id: contactId, name: '', type: '' },
        ]) || []

      const partitionContacts = [...actionPlan, ...contacts].map(
        (contact, index) => ({
          id: contact.contactId,
          priorityOrder: index + 1,
          actionable: contact.actionable,
          remoteControls: contact.remoteControls?.map((remoteControl) => ({
            id: remoteControl.id,
            name: remoteControl.name,
          })),
        }),
      )

      if (currentPartition) {
        mutatePutPartitionContacts(
          {
            partitionId,
            partition: {
              centralId: central.id,
              code: currentPartition.code,
              name: currentPartition.name,
              contacts: partitionContacts,
            },
          },
          {
            onSuccess: () => {
              addToast({
                message: 'Contato adicionado com sucesso.',
              })

              addContactModal.hide()
              refetch()
            },
            onError: () => {
              addToast({
                message: 'Erro ao adicionar contato. Tente novamente.',
                type: 'alert',
                error: true,
              })
            },
          },
        )
      }
    },
    [
      addContactModal,
      addToast,
      central.id,
      central.partitions,
      mutatePutPartitionContacts,
      refetch,
    ],
  )

  return (
    <>
      {isPending && <Loader />}
      {addContactModal.isVisible && (
        <AddContactModal
          onClose={addContactModal.hide}
          onSave={(contactId: string) =>
            handleAddPartitionContact(contactId, currentPartition?.id || '')
          }
          contacts={[
            ...(currentPartition?.actionPlan?.map((contact) => contact.id) ||
              []),
            ...(currentPartition?.contacts?.map((contact) => contact.id) || []),
          ]}
        />
      )}
      {central.partitions?.length && (
        <div className={styles.partitionsContainer}>
          <div className={styles.partitions}>
            {central.partitions
              .sort((a, b) => {
                const codeA = a.code.toLowerCase()
                const codeB = b.code.toLowerCase()

                return codeA < codeB ? -1 : codeA > codeB ? 1 : 0
              })
              .map((partition) => {
                const hasActionPlan = !!partition?.actionPlan?.length
                const hasOtherContacts = !!partition?.contacts?.length
                return (
                  <section key={partition.id} className={styles.partition}>
                    {removePartitionModal.isVisible && (
                      <ModalV2.Root
                        isOpen
                        onClose={removePartitionModal.hide}
                        key={partition.id}
                      >
                        <ModalV2.Content>
                          <ModalV2.Title>Remover partição</ModalV2.Title>
                          <p>Tem certeza que deseja remover esta partição?</p>
                          <ModalV2.Footer>
                            <ModalV2.Close asChild>
                              <ButtonV2
                                key={partition.id}
                                appearance="tertiary"
                              >
                                Cancelar
                              </ButtonV2>
                            </ModalV2.Close>
                            <ButtonV2
                              disabled={removePartitionIsPending}
                              onClick={() =>
                                onRemovePartition(currentPartition?.id)
                              }
                            >
                              Confirmar
                            </ButtonV2>
                          </ModalV2.Footer>
                        </ModalV2.Content>
                      </ModalV2.Root>
                    )}
                    <div className={styles.title}>
                      <div className={styles.partitionTitle}>
                        {`Partição ${partition.code} - ${partition.name}`}
                        <span
                          className={
                            styles[
                              partition.status === 'D' ? 'unarmed' : 'armed'
                            ]
                          }
                        >
                          {getPartitionStatusLabel(partition.status)}
                        </span>
                        {!central.hasCommandSupport && (
                          <DeleteButton
                            onClick={() => {
                              setCurrentPartition(partition)
                              removePartitionModal.show()
                            }}
                            partition={partition}
                          />
                        )}
                      </div>
                      <Button
                        buttonTitle="Adicionar contato"
                        type="tertiary"
                        className={styles.entityButton}
                        icon={PlusSign}
                        onClick={() => {
                          setCurrentPartition(partition)
                          addContactModal.show()
                        }}
                      />
                    </div>
                    <div className={styles.list}>
                      {hasActionPlan && (
                        <div className={styles.container}>
                          <div className={styles.listHead}>
                            <h1 className={styles.text}>Plano de ação</h1>
                          </div>

                          <DragAndDrop
                            items={partition.actionPlan}
                            getItem={(item: ActionPlan) => ({
                              id: item.id,
                              title: item.name,
                              subtitle: getContactTypeLabel(
                                item.type as ContactType,
                              ),
                              email: item.email,
                              phone:
                                item.phone &&
                                formatPhone(
                                  PhoneType.CellPhone,
                                  `${item.phone.provinceCode}${item.phone.number}`,
                                ),
                              canDelete: true,
                            })}
                            type="actionPlan"
                            draggable={true}
                            onChange={(item: ActionPlan[]) =>
                              handleOrdinationActionPlan(item, partition.id)
                            }
                            onDelete={(item) =>
                              handleRemoveContact(
                                partition.id,
                                item.id,
                                'actionPlan',
                              )
                            }
                          />
                        </div>
                      )}
                      {hasOtherContacts && (
                        <div className={styles.container}>
                          <div className={styles.listHead}>
                            <h1 className={styles.text}>Demais contatos</h1>
                          </div>

                          <DragAndDrop
                            items={partition.contacts}
                            getItem={(item: PartitionContacts) => ({
                              id: item.id,
                              title: item.name,
                              subtitle: getContactTypeLabel(
                                item.type as ContactType,
                              ),
                              type: 'contacts',
                              icon: item.remoteControls?.length
                                ? ControlIcon
                                : undefined,
                              email: item.email,
                              phone:
                                item.phone &&
                                formatPhone(
                                  PhoneType.CellPhone,
                                  `${item.phone.provinceCode}${item.phone.number}`,
                                ),
                            })}
                            type="contacts"
                            draggable={false}
                            onChangeRemoteControl={(
                              item: PartitionContacts,
                              oldContact: PartitionContacts,
                            ) =>
                              handleChangeRemoteControl(
                                item,
                                partition.id,
                                oldContact,
                              )
                            }
                            onDelete={(item) =>
                              handleRemoveContact(
                                partition.id,
                                item.id,
                                'contact',
                              )
                            }
                            onEdit={(item) =>
                              handleAddActionPlan(partition.id, item.id)
                            }
                          />
                        </div>
                      )}
                      {!hasActionPlan && !hasOtherContacts && (
                        <span className={styles.noHaveContacts}>
                          Ainda não existem contatos vinculados a esta partição.
                        </span>
                      )}
                    </div>
                  </section>
                )
              })}
          </div>
        </div>
      )}
    </>
  )
}
