import { FC, useRef } from 'react'
import { ButtonV2 as Button, Tooltip, Icon } from 'components'
import { PartitionFragment } from 'services/central/types'
import { useToggle } from 'shared/hooks'

type DeleteButtonProps = {
  partition: PartitionFragment
  onClick: () => void
}

const DeleteButton: FC<DeleteButtonProps> = ({ partition, onClick }) => {
  const tooltip = useToggle()
  const ref = useRef(null)

  return (
    <div ref={ref} onMouseEnter={tooltip.show} onMouseLeave={tooltip.hide}>
      <Button
        appearance="tertiary"
        aria-label="Remover"
        onClick={(e) => {
          if (partition.deletionWarning) {
            e.preventDefault()
            return
          }
          onClick()
        }}
        disabled={!!partition.deletionWarning}
      >
        <Icon name="delete" width={14} />
      </Button>

      {!!partition.deletionWarning && tooltip.isVisible && (
        <Tooltip
          parentRef={ref}
          type="informative"
          isVisible={tooltip.isVisible}
        >
          {partition.deletionWarning?.defaultPartition && (
            <p>
              Não é possível excluir esta partição, pois ela é a partição padrão
              da central.
            </p>
          )}
          {partition.deletionWarning?.linkedDevices && (
            <p>
              Não é possível excluir esta partição, pois existem dispositivos
              vinculados a ela.
            </p>
          )}
        </Tooltip>
      )}
    </div>
  )
}

export default DeleteButton
